var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.user)?_c('div',{class:[{ details_open: _vm.toggleDetailsStatus }, 'details'],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;$event.stopPropagation();}}},[_c('VButtonClose',{staticClass:"mobile-details",attrs:{"action":_vm.hideDetails,"isEnd":""}}),_c('VUserBlock',{staticClass:"details__user-card",attrs:{"user":_vm.user},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('div',{staticClass:"details__user-card-name"},[_c('span',{staticClass:"details__user-card-name-text"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('div',{staticClass:"details__user-card-role"},[_vm._v(" "+_vm._s(_vm.userRoleName)+" ")])])]},proxy:true}],null,false,2533763450)}),(_vm.isPrescriptionService)?_c('ul',{staticClass:"details__rows mobile-details"},[_c('li',{staticClass:"details__row"},[_c('RouterLink',{staticClass:"details__link",attrs:{"title":"Проекты","to":{
                    name: 'prescription.home',
                }}},[_vm._v(" Проекты ")])],1),_c('li',{staticClass:"details__row"},[_c('RouterLink',{staticClass:"details__link",attrs:{"title":_vm.$t('titles.snips'),"to":{
                    name: 'prescription.requirements',
                }}},[_vm._v(" "+_vm._s(_vm.$t('page.home.snip'))+" ")])],1),_c('li',{staticClass:"details__row"},[_c('RouterLink',{staticClass:"details__link",attrs:{"title":_vm.$t('titles.catalog'),"to":{
                    name: 'prescription.list',
                    query: _vm.$route.query,
                }}},[_vm._v(" "+_vm._s(_vm.$t('titles.catalog'))+" ")])],1)]):_vm._e(),_c('ul',{staticClass:"details__rows mobile-details",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;$event.stopPropagation();}}},[_c('li',{staticClass:"details__row"},[_c('RouterLink',{staticClass:"details__link",attrs:{"title":_vm.$t('titles.catalog'),"to":{ name: 'prescription.home' }}},[_vm._v(" "+_vm._s(_vm.$t('services.prescription'))+" ")])],1),_c('li',{staticClass:"details__row"},[_c('RouterLink',{staticClass:"details__link",attrs:{"title":_vm.$t('titles.catalog'),"to":{ name: 'cloud' }}},[_vm._v(" "+_vm._s(_vm.$t('services.cloud'))+" ")])],1),_c('li',{staticClass:"details__row"},[_c('RouterLink',{staticClass:"details__link",attrs:{"title":_vm.$t('titles.catalog'),"to":{ name: 'qlik' }}},[_vm._v(" "+_vm._s(_vm.$t('services.qlik'))+" ")])],1)]),_c('div',{staticClass:"details__logout"},[_c('div',{staticClass:"details__snow-toggler"},[_c('SnowflakeSVG',{attrs:{"height":"18","width":"18"}}),_c('div',{staticClass:"toggler"},[_c('input',{staticClass:"toggler__checkbox",attrs:{"type":"checkbox","id":"snow"},domProps:{"checked":_vm.showSnow},on:{"click":_vm.toggleSnow}}),_vm._m(0)])],1),_c('div',{staticClass:"logout",on:{"click":function($event){$event.preventDefault();return _vm.$oidc.signOut()}}},[_c('span',{staticClass:"logout__text"},[_vm._v(_vm._s(_vm.$t('user.desk.logout')))]),_c('LogoutSVG',{attrs:{"height":"18","width":"18"}})],1)])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"toggler__label",attrs:{"for":"snow"}},[_c('span',{staticClass:"toggler__ball"})])
}]

export { render, staticRenderFns }